var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Omset Setting List"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.omset,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(income_source)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.income_source.name || '-')+" ")]}},{key:"cell(start_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date || '-')+" ")]}},{key:"cell(end_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.end_date || '-')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }