<template>
	<div>
		<b-card title="Omset Setting List">
			<!-- <b-row class="mb-2">
				<b-col cols="12" md="6">
					<b-button
						@click="cleanUpForm()" 
						v-b-modal.form-omset-modal 
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
						v-if="checkPermission('add banner')"
					>
						Create Omset Setting
					</b-button>
				</b-col>
				<b-col cols="12" md="6">
					<form class="form-inline justify-content-end">
						<div class="d-flex">
							<div class="position-relative">
								<input 
									type="search"
									class="form-control"
									v-model="filter.keyword" 
									placeholder="Banner Name" 
									aria-label="Search"
								>
							</div>
							<div class="position-relative">
								<flat-pickr
									v-model="filter.date"
									class="form-control ml-sm-2"
									:config="{ enableTime: true,dateFormat: 'Y-m-d'}"
								/>
							</div>
							<button 
								:disabled="!filter.keyword && !filter.date" 
								@click="filter.keyword = '' , filter.date = ''" 
								class="btn btn-outline-secondary ml-sm-2"
							>
								Reset
							</button>
						</div>
					</form>
				</b-col>
			</b-row> -->
			<b-table 
				striped 
				hover 
				:items="omset" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>

				<template v-slot:cell(no)="{ index }">
					{{ index + 1 }}
				</template>

				<template v-slot:cell(income_source)="{ item }">
					{{ item.income_source.name || '-' }}
				</template>

				<template v-slot:cell(start_date)="{ item }">
					{{ item.start_date || '-' }}
				</template>

				<template v-slot:cell(end_date)="{ item }">
					{{ item.end_date || '-' }}
				</template>
				
				<!-- <template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<b-badge 
							variant="warning"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="EditIcon"
								class="cursor-pointer"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Edit Data'"
								@click="editItem(item)"
								v-if="checkPermission('update banner')"
							/>
						</b-badge>
						
						<b-badge 
							variant="danger"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="TrashIcon"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Delete Data'"
								v-if="checkPermission('delete banner')"
								@click="deleteItem(item.uuid)"
							/>
						</b-badge>

					</div>
				</template> -->
			</b-table>
			<!-- <b-modal
				id="form-omset-modal" 
				centered
				:title="editId != null ? 'Edit Omset Setting' : 'Add Omset Setting'"
				size="lg"
				no-close-on-backdrop
				hide-footer
			>
				<ValidationObserver>
					<div class="form">
						<div class="form-group">
							<label>Nama Banner *</label>
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Nama Banner" 
									v-model="formPayload.name"
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						
					</div>
					<div v-if="isLoading">
						<br>
						<b-spinner
							class="mb-2"
							variant="primary"
						/><br>
					</div>
					<b-button v-if="editId == null" variant="primary" @click="createItem()" v-bind:disabled="isLoading">
						Save Omset Setting
					</b-button>
					<b-button v-else variant="primary" @click="updateItem()" v-bind:disabled="isLoading">
						Edit Omset Setting
					</b-button>
				</ValidationObserver>
			</b-modal> -->
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BTable,
	BPagination,
	BFormFile,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBTooltip
} from 'bootstrap-vue'
import _ from 'lodash'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BTable,
		BPagination,
		BFormFile,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol,
		vSelect,
		flatPickr,
		ValidationProvider,
		ValidationObserver
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			checkPermission,
			errorNotification,
			successNotification,
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no' ,'free_tax_amount', 'income_source', 'start_date', 'end_date', 'percentage' ,'actions'],
			omset: [],
			filter: {
				keyword: '',
				date: null,
			},
			formPayload: Object,
			pages:[],
			displayInput: '',
			// uuid: '',
			editId: null,
			validations: '',
			selected: '',
			hotlink: ''
		}
	},
	computed: {
		rows() {
			return this.banners.length
		},
	},
	
	// mounted() {
	// 	this.loadPage(),
	// 	this.loadPartner()
	// },

	methods: {
		modalId(id) {
			return 'modal_' + id;
		},
		cleanUpForm() {
			this.editId = null
			this.formPayload = {
				name: '',
				image: '',
				url: '',
				start_date: null,
				end_date: null,
				page_uuid: '',
				partner:[],
			};
			this.partnerSelected = [],
			this.hotlink = 'feature',
			this.selected = null
		},
		preparePayload() {
			const form = new FormData()
			this.formPayload.partner_uuids = this.partnerSelected.map(partner => partner.uuid)
			console.log(this.formPayload.partner_uuids);

			this.selected != null ? this.formPayload.page_uuid = this.selected.uuid : ''

			if (this.hotlink == 'url') {
				this.formPayload.page_uuid = ''
			} else {
				this.formPayload.url = ''
			}
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key)) {	
					if(key != 'image' && key != 'partner_uuids'){
						form.append(key, this.formPayload[key])
					} else {
						if(typeof this.formPayload.image != 'string') {
							form.append('image', this.formPayload['image'])
						}
					}
				}
			}
			if (this.formPayload.partner_uuids) {
				for (let index = 0; index < this.formPayload.partner_uuids.length; index++) {
					const element = this.formPayload.partner_uuids[index];
					form.append('partner_uuids[]', element)
				}
			}
			return form;
		},
		createItem() {
		var form = this.preparePayload();
			this.isLoading = true
			this.$http.post('admin/banners', form, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-omset-modal')
				this.getData(this.currentPage)
				successNotification(this, 'Success', 'Banner successfully created')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages.length > 0) {
				errorNotification(this, 'Oops!', error.response.data.meta.messages)  
				}
				this.isLoading = false
			})
		},
		updateItem() {
		var form = this.preparePayload();
			this.isLoading = true
			form.append('_method', 'PATCH');
			this.$http.post(`admin/banners/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-omset-modal')
				this.getData()
				successNotification(this, 'Success', 'Banner successfully updated!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages.length > 0) {
				errorNotification(this, 'Oops!', error.response.data.meta.messages)          
				}
				this.isLoading = false
			})
		},
		editItem(item) {
			console.log(item);
			this.cleanUpForm();
			this.editId = item.uuid
			this.formPayload = {
				name: item.name,
				image: item.images,
				url: item.url,
				start_date: item.start_date,
				end_date: item.end_date,
				// page_uuid: item.page
			}
			this.partnerSelected = item.partner
			this.selected = item.page
			this.selected != null ? this.hotlink = 'feature' : 'url' 
			this.formPayload.url != null ? this.hotlink = 'url' : 'feature'
			if (this.hotlink == 'feature') {
				this.formPayload.url = ''
			} else if (this.hotlink == 'url') {
				this.selected = null
			}
			this.$bvModal.show('form-omset-modal')

		},
		// async loadPage() {
		// 	this.$http.get('admin/pages')
		// 	.then(response => {
		// 		const permissionData = response.data.data
		// 		permissionData.forEach(element => {
		// 			this.pages.push({
		// 				'uuid': element.uuid,
		// 				'title': element.title
		// 			})
		// 		});
		// 	})
		// },
		// async loadPartner() {
		// 	this.$http.get('admin/partners')
		// 	.then(response => {
		// 		response.data.data.forEach(element => {
		// 			this.partner.push({'uuid':element.uuid, 'name':element.name})
		// 		});
		// 		console.log(this.partner);
		// 	})
		// },
		getData(page) { //page = 1
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/income-tax-settings' ,
			{
				params: queryParams,
			}).then(response => {
				this.omset = response.data.data.data
				console.log(this.omset);
				this.isLoading = false
			})
		},
		deleteItem(uuid) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Banner ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/banners/' + uuid)
					this.getData()
					successNotification(this, 'Success', 'Banners berhasil dihapus!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},
	created() {
		this.getData();
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
	.tooltip_hint {
		border-radius: 50%;
		background: #000;
		color: #fff;
		padding: 0px 4px;
	}
	.image__hint {
		font-size: 10px;
		margin-bottom: 0;
	}
	.custom-label {
		border-color: #ebedf2;
		background-color: #f4f5f8;
		color: #575962;
		padding: 0.85rem 1.15rem;
	}
	#preview {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	#preview img {
		max-width: 100%;
		max-height: 500px;
	}
	.image_preview {
		max-width: 150px;
		max-height: 150px;
	}
</style>